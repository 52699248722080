<template>
  <div class="add-new-administrator">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.administratorCreate')"
      :title="$t('messages.administratorCreate')"
      @submitPressed="submitPressed"
    />
    <administrator-form :administratorObject="administrator" :action="action" @clearAction="action = null"/>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import AdministratorForm from './components/AdministratorForm.vue'

export default {
  components: {
    TitleBar,
    AdministratorForm
  },
  data() {
    return {
      action: null,
      administrator: {
        role: "ROLE_ADMIN",
      }
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  }
}
</script>
